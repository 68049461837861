import { PureComponent } from 'react';
import './style.scss';

interface TabProps {
  children: JSX.Element[];
}

export default class Tab extends PureComponent<TabProps> {
  render() {
    return this.props.children;
  }
}
interface TabChildrenProps {
  children: JSX.Element[];
  className: string;
}

export const TabPane = ({ children, className }: TabChildrenProps): JSX.Element => {
  return <div className={className}>{children}</div>;
};

export const TabContent = ({ children, className }: TabChildrenProps): JSX.Element => {
  return <div className={className}>{children}</div>;
};
