import { PureComponent } from 'react';
import Tab, { TabPane, TabContent } from '@pages/long-term-rental/component/tab';
import Contact from './contact';
import Quotation from './quotation';
import { trackLongTermForm, LongTermForm } from './tracking';

const FORM_NAME_CONTACT = 'long-term-rental-contact-form';
const FORM_NAME_QUOTATION = 'long-term-rental-quotation-form';

interface LongTermRentalFormState {
  hash: string;
  isLoading: boolean;
}
interface LongTermRentalFormProps {
  prefix: string;
}

class LongTermRentalForm extends PureComponent<LongTermRentalFormProps, LongTermRentalFormState> {
  static defaultProps = {
    active: 0
  };
  constructor(props: LongTermRentalFormProps) {
    super(props);

    this.state = {
      hash: 'quotation',
      isLoading: false
    };
  }

  getClassNameListItem = (key: string): string => {
    return `list-group-item list-group-item-action ${this.state.hash === key ? 'active' : ''}`;
  };
  getClassNameContentItem = (key: string): string => {
    return `tab-pane ${this.state.hash === key ? 'active' : ''}`;
  };

  render(): React.ReactNode {
    return (
      <div id="longterm-rental-form">
        <div className="container">
          <h2>แบบฟอร์มเช่ารถระยะยาว</h2>
          <Tab>
            <TabPane className="list-group list-group-horizontal list-menu">
              <div
                className={this.getClassNameListItem('contact')}
                onClick={(): void => this.setState({ hash: 'contact' }, () => trackLongTermForm(LongTermForm.Contact))}
              >
                <div className="d-flex w-100 justify-content-center text-center">
                  <div>
                    <h4 className="mb-0">ต้องการให้เราติดต่อกลับ</h4>
                  </div>
                </div>
              </div>
              <div
                className={this.getClassNameListItem('quotation')}
                onClick={(): void =>
                  this.setState({ hash: 'quotation' }, () => trackLongTermForm(LongTermForm.Quotation))
                }
              >
                <div className="d-flex w-100 justify-content-center text-center">
                  <div>
                    <h4 className="mb-0">ต้องการให้เราออกใบเสนอราคา</h4>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabContent className="tab-content">
              <div className={this.getClassNameContentItem('contact')} id="contact" role="tabpanel">
                <Contact formName={FORM_NAME_CONTACT} prefix={this.props.prefix} />
              </div>
              <div className={this.getClassNameContentItem('quotation')} id="quotation" role="tabpanel">
                <Quotation formName={FORM_NAME_QUOTATION} prefix={this.props.prefix} />
              </div>
            </TabContent>
          </Tab>
        </div>
      </div>
    );
  }
}

export default LongTermRentalForm;
