export const HowToRent = () => {
  return (
    <div id="how-to-rent" className="bg-gray">
      <div className="container">
        <h2>เช่ารถระยะยาวกับเราต้องทำอย่างไร?</h2>
        <div className="item-wrap">
          <div className="item">
            <img src="/assets/shares/fill-form-icon.png" alt=" กรอกแบบฟอร์มเสนองบประมาณ เพื่อให้เราติดต่อกลับ" />
            <p>1. กรอกแบบฟอร์มเสนองบประมาณ เพื่อให้เราติดต่อกลับ</p>
          </div>
          <div className="arrow">
            <i className="icon-right"></i>
          </div>
          <div className="item">
            <img
              src="/assets/shares/car-select-icon.png"
              alt="ทางเราจะหารถคันที่เหมาะสมให้คุณ และส่งรูปให้คุณดูก่อนตัดสินใจเลือกเช่า"
            />
            <p>2. ทางเราจะหารถคันที่เหมาะสมให้คุณ และส่งรูปให้คุณดูก่อนตัดสินใจเลือกเช่า</p>
          </div>
          <div className="arrow">
            <i className="icon-right"></i>
          </div>
          <div className="item">
            <img src="/assets/shares/confirm-icon.png" alt="ยืนยันและรอรับรถจากร้านรถเช่า" />
            <p>3. ยืนยันและรอรับรถจากร้านรถเช่า</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToRent;
