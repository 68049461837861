import { PureComponent } from 'react';
import { navigate } from 'gatsby';
// import { withTranslation } from '../locales';
import axios from 'axios';
import { withTrans } from '@shares/locales/hoc';
import { Field, FieldProps, FormikErrors, withFormik, FormikProps } from 'formik';
import { string as yupString, object as yupObject } from 'yup';
import { API_LONG_TERM_RENTAL, WEB_LEGACY_LINK } from '@config';
import { trackContactForm, LongTermFormResp } from './tracking';
interface CheckboxProps {
  name: string;
  value: string;
  raw: string;
}
const Checkbox = (props: CheckboxProps) => {
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => (
        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              {...props}
              checked={field.value.includes(props.value)}
              onChange={() => {
                if (field.value.includes(props.value)) {
                  const nextValue = field.value.filter((value: string) => value !== props.value);
                  form.setFieldValue(props.name, nextValue);
                } else {
                  const nextValue = field.value.concat(props.value);
                  form.setFieldValue(props.name, nextValue);
                }
              }}
            />
            {` ${props.value}`}
          </label>
        </div>
      )}
    </Field>
  );
};
export interface ContactInitialValues {
  full_name: string;
  email: string;
  phone_number: string;
  time: string;
  purposes: string[];
  purpose_other?: string;
}

interface ContactProps extends PageWithTranslationProps {
  formName: string;
}

const timeOptions = [
  '08:00 - 12:00',
  '12:00 - 13:00',
  '13:00 - 15:00',
  '15:00 - 17:00',
  '17:00 - 18:00',
  '18:00 - 20:00'
];

const purposes = ['purposes.waiting', 'purposes.company', 'purposes.travel', 'purposes.other'];
const contactSchema = () => {
  const phoneRegExp = /([0-9]{9})$/;
  return yupObject().shape({
    full_name: yupString().required('กรุณากรอกชื่อของคุณ'),
    phone_number: yupString()
      .matches(phoneRegExp, 'เบอร์โทรศัพท์ของคุณไม่ถูกต้อง')
      .required('กรุณากรอกเบอร์โทรศัพท์ของคุณ'),
    email: yupString().email('อีเมลของคุณไม่ถูกต้อง').required('กรุณากรอกอีเมลของคุณ')
  });
};
class Contact extends PureComponent<ContactProps & FormikProps<ContactInitialValues>> {
  componentDidUpdate(prevProps: ContactProps & FormikProps<ContactInitialValues>) {
    if (prevProps.isSubmitting !== this.props.isSubmitting) {
      if (Object.keys(this.props.errors).length > 0) {
        trackContactForm(LongTermFormResp.Fail, this.props.values);
      }
    }
  }
  buttonText(errors: FormikErrors<ContactInitialValues>) {
    if (this.props.status && this.props.status.isLoading) {
      return 'กำลังส่งใบเสนอราคา...';
    }
    if (Object.keys(errors).length > 0) {
      return errors[Object.keys(errors)[0]];
    }
    return this.props.t('personal.submit');
  }
  render() {
    const { t, handleSubmit, handleChange, handleBlur, values, formName, errors, status } = this.props;

    return (
      <div>
        <h3>{t('personal.title')}</h3>
        <p>{t('personal.subTitle')}</p>
        <form onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value={formName} />
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>{t('personal.fullName_label')}</label>
                <input
                  name="full_name"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.full_name}
                  placeholder={t('personal.fullName_placeholder')}
                />
              </div>
              <div className="form-group">
                <label>{t('personal.email_label')}</label>
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder={t('personal.email_placeholder')}
                />
              </div>

              <div className="form-group">
                <label>{t('detail.purposes_label')}</label>
                {purposes.map((purpose) => (
                  <Checkbox name="purposes" key={purpose} value={t(purpose)} raw={purpose} />
                ))}
                {values.purposes.includes(t('purposes.other')) && (
                  <input
                    name="purpose_other"
                    className="form-control"
                    value={values.purpose_other}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>{t('personal.phoneNumber_label')}</label>
                <input
                  name="phone_number"
                  className="form-control"
                  type="tel"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number}
                  placeholder={t('personal.phoneNumber_placeholder')}
                />
              </div>
              <div className="form-group">
                <label>{t('personal.time_label')}</label>
                <select
                  name="time"
                  value={values.time}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="form-control"
                >
                  {timeOptions.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row justify-content-md-center mt-3">
            <div className="col-12 col-md-6">
              <button
                disabled={Object.keys(errors).length > 0 || (status && status.isLoading === true)}
                type="submit"
                className="btn btn-primary btn-block"
              >
                {this.buttonText(errors)}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const ContactWithFormik = withFormik<ContactProps, ContactInitialValues>({
  mapPropsToValues: () => ({
    full_name: '',
    email: '',
    phone_number: '',
    time: timeOptions[0],
    purposes: [],
    purpose_other: ''
  }),
  validationSchema: contactSchema(),
  validateOnChange: true,
  validateOnBlur: true,
  handleSubmit: (values, formikBag) => {
    const { t } = formikBag.props;
    const newValues = { ...values };

    if (values.purposes.includes(t('purposes.other'))) {
      newValues.purposes.push(values.purpose_other || '');
      newValues.purposes = newValues.purposes.filter((p) => p !== t('purposes.other'));
    }
    delete newValues.purpose_other;
    formikBag.setStatus({
      isLoading: true
    });
    axios({
      method: 'post',
      url: `${API_LONG_TERM_RENTAL}/long-term-rental/contact`,
      data: {
        ...values
      }
    })
      .then(() => {
        formikBag.setStatus({
          isSuccess: true,
          isLoading: false
        });
        trackContactForm(LongTermFormResp.Success, values);
        navigate(`${WEB_LEGACY_LINK}/th/long-term-rental/successfully`);
      })
      .catch((e) => {
        formikBag.setStatus({
          isSuccess: false,
          isLoading: false
        });
        console.log(e);
      });
    formikBag.setSubmitting(false);
  },
  enableReinitialize: false
})(Contact);

export default withTrans('long-term-rental')(ContactWithFormik);
