import { FunctionComponent, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import Background from '@shares/background';
import { trackBookBtn } from './tracking';

export const LongTermRentalHeader: FunctionComponent = () => {
  const onClickLongTermBtn = () => {
    const eleRef = document.getElementById('longterm-rental-form');
    if (!eleRef) return;
    trackBookBtn();
    eleRef.scrollIntoView({ behavior: 'smooth' });
  };
  const data = useStaticQuery<{ file: IGatsbyImageData }>(graphql`
    query LTRhero {
      file(absolutePath: { regex: "/long-term-rental-header.png/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `);
  const heroSrc = useMemo(() => {
    return data.file;
  }, [data]);

  return (
    <Background src={heroSrc} id="long-term-rental-section-bg">
      <div className="banner-container">
        <div className="overlay" />
        <div className="content-section">
          <div className="heading-text">
            <h1 className="mx-3">เช่ารถระยะยาว รายอาทิตย์และรายเดือน</h1>
            <h4>จาก 500 กว่าบริษัทรถเช่าทั่วประเทศ</h4>
          </div>
          <div onClick={() => onClickLongTermBtn()} className="btn btn-primary">
            เริ่มต้นเช่ารถกับเรา
          </div>
          <div className="valueprop">
            <ul className="list-inline">
              <li className="list-inline-item">กำหนดงบได้</li>
              <li className="list-inline-item">รถใหม่ ราคาดี</li>
              <li className="list-inline-item">เห็นรถจริงก่อนเช่า</li>
            </ul>
          </div>
        </div>
      </div>
    </Background>
  );
};

export default LongTermRentalHeader;
