import { sendToDataLayer, getDefaultEventCategory, PageSection, sha256, convertToDateAndTime } from '@utils';
import { ContactInitialValues } from './contact';
import { QuotationInitialValues } from './quotation';
import moment from 'moment';
export enum LongTermForm {
  Contact = 'drivehub_callback',
  Quotation = 'drivehub_callback_quotation'
}
export enum LongTermFormResp {
  Success = 'success',
  Fail = 'not_success'
}
export const trackLongTermForm = (which: LongTermForm) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.LongTermRental),
    event_action: 'select_tabs',
    event_label: `${which}`
  });
};

export const trackLongTermCarType = (carType?: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.LongTermRental),
    event_action: 'select',
    event_label: carType ? `cartype_${carType}` : `undefined`
  });
};

export const trackLongTermProvince = (provinceName: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.LongTermRental),
    event_action: 'select',
    event_label: `province_${provinceName}`
  });
};

export const trackLongTermBudget = (budget: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.LongTermRental),
    event_action: 'select',
    event_label: `budget_${budget.replace(/,/g, '').replace(/ /g, '_')}`
  });
};

const getFieldName = (fieldName: string, inputType: string) => {
  if (fieldName.includes('pickup')) return `pickup_${inputType}`;
  if (fieldName.includes('return')) return `return_${inputType}`;
  return 'undefined';
};

export const trackLongTermDate = (fieldName: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.LongTermRental),
    event_label: `${getFieldName(fieldName, 'date')}`
  });
};

export const trackLongTermTime = (fieldName: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.LongTermRental),
    event_label: `${getFieldName(fieldName, 'time')}`
  });
};

export const trackContactForm = async (formResp: LongTermFormResp, reqValue: ContactInitialValues) => {
  let encryptEmail = 'undefined';
  let encryptPhoneNumber = 'undefined';
  if (reqValue.email !== '') {
    await sha256(reqValue.email).then((resp) => {
      encryptEmail = resp;
    });
  }
  if (reqValue.phone_number !== '') {
    await sha256(reqValue.phone_number).then((resp) => {
      encryptPhoneNumber = resp;
    });
  }

  const customDimension = {
    rental_purpose: `${reqValue.purposes.length > 0 ? reqValue.purposes.join(',') : 'undefined'}`,
    email: `${encryptEmail}`,
    phone_number: `${encryptPhoneNumber}`,
    call_back_time: `${reqValue.time}`
  };
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.LongTermRental),
    event_action: `drop_form_${formResp}`,
    event_label: `drivehub_callback`,
    ...customDimension
  });
};

export const trackQuotationForm = async (formResp: LongTermFormResp, reqValue: QuotationInitialValues) => {
  let encryptEmail = 'undefined';
  let encryptPhoneNumber = 'undefined';
  let bookingBegin = {
    date: 'undefined',
    time: 'undefined'
  };
  let bookingEnd = {
    date: 'undefined',
    time: 'undefined'
  };
  if (reqValue.email !== '') {
    await sha256(reqValue.email).then((resp) => {
      encryptEmail = resp;
    });
  }
  if (reqValue.phone_number !== '') {
    await sha256(reqValue.phone_number).then((resp) => {
      encryptPhoneNumber = resp;
    });
  }
  if (reqValue.pickup_at && reqValue.return_at) {
    bookingBegin = convertToDateAndTime(moment(reqValue.pickup_at).format('YYYY-MM-DD HH:mm'));
    bookingEnd = convertToDateAndTime(moment(reqValue.return_at).format('YYYY-MM-DD HH:mm'));
  } else {
    if (!reqValue.pickup_date || !reqValue.return_date) return;
    bookingBegin = convertToDateAndTime(`${reqValue.pickup_date.format('YYYY-MM-DD')} ${reqValue.pickup_hour}`);
    bookingEnd = convertToDateAndTime(`${reqValue.return_date.format('YYYY-MM-DD')} ${reqValue.return_hour}`);
  }

  const customDimension = {
    car_type: `${reqValue.car_type}`,
    rental_purpose: `${reqValue.purposes.length > 0 ? reqValue.purposes.join(',') : 'undefined'}`,
    pickup_date: `${bookingBegin.date}`,
    pickup_time: `${bookingBegin.time}`,
    return_date: `${bookingEnd.date}`,
    return_time: `${bookingEnd.time}`,
    encrypt_email: `${encryptEmail}`,
    encrypt_phonenumber: `${encryptPhoneNumber}`,
    call_back_time: `${reqValue.time}`
  };
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.LongTermRental),
    event_action: `drop_form_${formResp}`,
    event_label: `drivehub_callback_quotation`,
    ...customDimension
  });
};
