export const provinces = {
  th: [
    'กรุงเทพมหานคร',
    'เชียงใหม่',
    'เชียงราย',
    'ขอนแก่น',
    'ภูเก็ต',
    'กาฬสินธุ์',
    'กำแพงเพชร',
    'กาญจนบุรี',
    'กระบี่',
    'จันทบุรี',
    'ฉะเชิงเทรา',
    'ชัยนาท',
    'ชลบุรี',
    'ชัยภูมิ',
    'ชุมพร',
    'ตราด',
    'ตาก',
    'ตรัง',
    'นนทบุรี',
    'นครนายก',
    'นครราชสีมา',
    'นครพนม',
    'น่าน',
    'นครสวรรค์',
    'นครปฐม',
    'นครศรีธรรมราช',
    'นราธิวาส',
    'บุรีรัมย์',
    'บึงกาฬ',
    'ปทุมธานี',
    'ปราจีนบุรี',
    'ประจวบคีรีขันธ์',
    'ปัตตานี',
    'พระนครศรีอยุธยา',
    'พะเยา',
    'พิษณุโลก',
    'พิจิตร',
    'พังงา',
    'พัทลุง',
    'เพชรบูรณ์',
    'เพชรบุรี',
    'แพร่',
    'มหาสารคาม',
    'มุกดาหาร',
    'แม่ฮ่องสอน',
    'ยโสธร',
    'ยะลา',
    'ระยอง',
    'ร้อยเอ็ด',
    'ราชบุรี',
    'ระนอง',
    'ลพบุรี',
    'เลย',
    'ลำพูน',
    'ลำปาง',
    'ศรีสะเกษ',
    'สมุทรปราการ',
    'สิงห์บุรี',
    'สระบุรี',
    'สระแก้ว',
    'สุรินทร์',
    'สกลนคร',
    'สุโขทัย',
    'สุพรรณบุรี',
    'สมุทรสาคร',
    'สมุทรสงคราม',
    'สุราษฎร์ธานี',
    'สงขลา',
    'สตูล',
    'หนองบัวลำภู',
    'หนองคาย',
    'อ่างทอง',
    'อุบลราชธานี',
    'อำนาจเจริญ',
    'อุดรธานี',
    'อุตรดิตถ์',
    'อุทัยธานี'
  ],
  en: [
    'Bangkok',
    'Chiang Mai',
    'Chiang Rai',
    'Khon Kaen',
    'Phuket',
    'Ang Thong',
    'Amnat Charoen',
    'Buri Ram',
    'Bueng Kan',
    'Chai Nat',
    'Chon Buri',
    'Chanthaburi',
    'Chachoengsao',
    'Chaiyaphum',
    'Chumphon',
    'Kalasin',
    'Kamphaeng Phet',
    'Kanchanaburi',
    'Krabi',
    'Lop Buri',
    'Loei',
    'Lamphun',
    'Lampang',
    'Maha Sarakham',
    'Mukdahan',
    'Mae Hong Son',
    'Nonthaburi',
    'Nakhon Nayok',
    'Nakhon Ratchasima',
    'Nong Bua Lam Phu',
    'Nong Khai',
    'Nakhon Phanom',
    'Nan',
    'Nakhon Sawan',
    'Nakhon Pathom',
    'Nakhon Si Thammarat',
    'Narathiwat',
    'Pathum Thani',
    'Phra Nakhon Si Ayutthaya',
    'Prachin Buri',
    'Phrae',
    'Phayao',
    'Phitsanulok',
    'Phichit',
    'Phetchabun',
    'Phetchaburi',
    'Prachuap Khiri Khan',
    'Phang-nga',
    'Phatthalung',
    'Pattani',
    'Rayong',
    'Roi Et',
    'Ratchaburi',
    'Ranong',
    'Samut Prakarn',
    'Sing Buri',
    'Saraburi',
    'Sa kaeo',
    'Surin',
    'Si Sa Ket',
    'Sakon Nakhon',
    'Sukhothai',
    'Suphan Buri',
    'Samut Sakhon',
    'Samut Songkhram',
    'Surat Thani',
    'Songkhla',
    'Satun',
    'Trat',
    'Tak',
    'Trang',
    'Ubon Ratchathani',
    'Udon Thani',
    'Uttaradit',
    'Uthai Thani',
    'Yasothon',
    'Yala'
  ]
};
