/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { PureComponent } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import { Field, FieldProps, FormikErrors, withFormik, FormikProps } from 'formik';
import { string as yupString, object as yupObject } from 'yup';
import { provinces } from './provinces';
import { withTrans } from '@shares/locales/hoc';
import moment from 'moment';

import { API_LONG_TERM_RENTAL, WEB_LEGACY_LINK } from '@config';
import {
  trackQuotationForm,
  LongTermFormResp,
  trackLongTermCarType,
  trackLongTermProvince,
  trackLongTermBudget,
  trackLongTermDate,
  trackLongTermTime
} from './tracking';
interface CheckboxProps {
  name: string;
  value: string;
  raw: string;
}
const Checkbox = (props: CheckboxProps) => {
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => (
        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              {...props}
              checked={field.value.includes(props.value)}
              onChange={() => {
                if (field.value.includes(props.value)) {
                  const nextValue = field.value.filter((value: string) => value !== props.value);
                  form.setFieldValue(props.name, nextValue);
                } else {
                  const nextValue = field.value.concat(props.value);
                  form.setFieldValue(props.name, nextValue);
                }
              }}
            />
            {` ${props.value}`}
          </label>
        </div>
      )}
    </Field>
  );
};
interface SelectProps {
  name: string;
  options: string[];
  placeholder: string;
  onChange: (value: string) => void;
}

const Select = (props: SelectProps) => {
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => (
        <div>
          <select
            className="form-control"
            name="province"
            onChange={(e) => {
              form.setFieldValue(props.name, e.target.value);
              props.onChange(e.target.value);
            }}
            value={field.value}
          >
            {props.options.map((value: string) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      )}
    </Field>
  );
};

interface DatePickerProps {
  name: string;
  placeholder: string;
}

const DatePicker = (props: DatePickerProps) => {
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => (
        <input
          type="date"
          min={moment().format('YYYY-MM-DD')}
          onChange={(e) => {
            const date = moment(e.target.value, 'YYYY-MM-DD');
            form.setFieldValue(props.name, date);
            if (props.name === 'pickup_date') {
              form.setFieldValue('return_date', moment(e.target.value, 'YYYY-MM-DD').endOf('month'));
            }
          }}
          onClick={() => trackLongTermDate(props.name)}
          value={field.value.format('YYYY-MM-DD')}
          className="form-control"
          placeholder="DDMM YY"
        />
      )}
    </Field>
  );
};

interface TimePickerProps {
  name: string;
  placeholder: string;
}
const TimePicker = (props: TimePickerProps) => {
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => (
        <input
          type="time"
          className="form-control"
          step={1800}
          onChange={(e) => form.setFieldValue(props.name, e.target.value)}
          value={field.value}
          onClick={() => trackLongTermTime(props.name)}
        />
      )}
    </Field>
  );
};

const timeOptions = [
  '08:00 - 12:00',
  '12:00 - 13:00',
  '13:00 - 15:00',
  '15:00 - 17:00',
  '17:00 - 18:00',
  '18:00 - 20:00'
];
const carTypes = [
  {
    type: 'eco',
    name: 'cars.eco'
  },
  {
    type: 'small',
    name: 'cars.small'
  },
  {
    type: 'medium',
    name: 'cars.medium'
  },
  {
    type: 'large',
    name: 'cars.large'
  },
  {
    type: 'suv',
    name: 'cars.suv'
  },
  {
    type: 'other',
    name: 'cars.other'
  }
];
const budgets = ['18,000 - 25,000', '25,000 - 35,000', '35,000 - 45,000', '45,000 - 50,000'];
const purposes = ['purposes.waiting', 'purposes.company', 'purposes.travel', 'purposes.other'];
export interface QuotationInitialValues {
  car_type: string;
  full_name: string;
  email: string;
  phone_number: string;
  time: string;
  province: string;
  purposes: string[];
  budget: string;
  note: string;
  purpose_other?: string;
  pickup_date?: moment.Moment;
  return_date?: moment.Moment;
  pickup_hour?: string;
  return_hour?: string;
  car_type_other?: string;
  pickup_at?: string;
  return_at?: string;
}
const quotationSchema = () => {
  const phoneRegExp = /([0-9]{9})$/;

  return yupObject().shape({
    full_name: yupString().required('กรุณากรอกชื่อของคุณ'),
    phone_number: yupString()
      .matches(phoneRegExp, 'เบอร์โทรศัพท์ของคุณไม่ถูกต้อง')
      .required('กรุณากรอกเบอร์โทรศัพท์ของคุณ'),
    email: yupString().email('อีเมลของคุณไม่ถูกต้อง').required('กรุณากรอกอีเมลของคุณ'),
    province: yupString().required('กรุณาระบุจังหวัด'),
    budget: yupString().required('กรุณากรอกงบประมาณ')
  });
};
interface QuotationProps extends PageWithTranslationProps {
  formName: string;
  prefix: string;
}
class Quotation extends PureComponent<QuotationProps & FormikProps<QuotationInitialValues>> {
  componentDidUpdate(prevProps: QuotationProps & FormikProps<QuotationInitialValues>) {
    console.log(this.props.values);

    if (prevProps.isSubmitting !== this.props.isSubmitting) {
      if (Object.keys(this.props.errors).length > 0) trackQuotationForm(LongTermFormResp.Fail, this.props.values);
    }
  }
  get provinces(): string[] {
    return provinces[this.props.prefix];
  }
  buttonText(errors: FormikErrors<QuotationInitialValues>) {
    if (this.props.status && this.props.status.isLoading) {
      return 'กำลังส่งใบเสนอราคา...';
    }
    if (Object.keys(errors).length > 0) {
      return errors[Object.keys(errors)[0]];
    }
    return this.props.t('quotation.submit');
  }
  render() {
    const { t, handleSubmit, handleChange, handleBlur, values, formName, errors, status } = this.props;
    return (
      <div>
        <h3>{t('detail.title')}</h3>
        <br />
        <form onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value={formName} />
          <input type="hidden" name="pickup_at" />
          <input type="hidden" name="return_at" />
          <div className="row mb-3">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>{t('detail.carType_label')}</label>
                <select
                  name="car_type"
                  className="form-control"
                  onChange={(e) => {
                    const selectedIndex = e.target.options.selectedIndex;
                    trackLongTermCarType(e.target.options[selectedIndex].getAttribute('data-type') || undefined);
                    handleChange(e);
                  }}
                  value={values.car_type}
                >
                  {carTypes.map((c) => {
                    const values = t(c.name) === null ? undefined : t(c.name);
                    return (
                      <option value={values} key={c.type} data-type={c.type}>
                        {t(c.name)}
                      </option>
                    );
                  })}
                </select>
              </div>
              {values.car_type === t('cars.other') && (
                <div className="form-group">
                  <input
                    name="car_type_other"
                    className="form-control"
                    onChange={handleChange}
                    value={values.car_type_other}
                    placeholder={t('cars.other')}
                  />
                </div>
              )}
              <div className="form-group">
                <label>{t('detail.budget_label')}</label>
                <select
                  name="budget"
                  className="form-control"
                  onChange={(e) => {
                    trackLongTermBudget(e.target.value);
                    handleChange(e);
                  }}
                  value={values.budget}
                >
                  {budgets.map((budget) => (
                    <option value={budget} key={budget}>
                      {budget}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>{t('detail.purposes_label')}</label>
                {purposes.map((purpose) => (
                  <Checkbox name="purposes" key={purpose} value={t(purpose)} raw={purpose} />
                ))}
                {values.purposes.includes(t('purposes.other')) && (
                  <input
                    name="purpose_other"
                    className="form-control"
                    value={values.purpose_other}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>{t('detail.province_label')}</label>
                <Select
                  name="province"
                  options={this.provinces}
                  placeholder={t('detail.province_label')}
                  onChange={(value) => trackLongTermProvince(value)}
                />
              </div>
              <div className="form-group">
                <label>{t('detail.pickup_label')}</label>
                <div className="row">
                  <div className="col-8">
                    <DatePicker name="pickup_date" placeholder={t('detail.pickupDate_placeholder')} />
                  </div>
                  <div className="col-4 pl-0">
                    <TimePicker name="pickup_hour" placeholder={t('detail.pickupHour_placeholder')} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>{t('detail.return_label')}</label>
                <div className="row">
                  <div className="col-8">
                    <DatePicker name="return_date" placeholder={t('detail.returnDate_placeholder')} />
                  </div>
                  <div className="col-4 pl-0">
                    <TimePicker name="return_hour" placeholder={t('detail.returnHour_placeholder')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3>{t('personal.title')}</h3>
          <div>{t('personal.subTitle')}</div>
          <br />
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>{t('personal.fullName_label')}</label>
                <input
                  name="full_name"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.full_name}
                  placeholder={t('personal.fullName_placeholder')}
                />
              </div>
              <div className="form-group">
                <label>{t('personal.email_label')}</label>
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder={t('personal.email_placeholder')}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>{t('personal.phoneNumber_label')}</label>
                <input
                  name="phone_number"
                  className="form-control"
                  type="tel"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number}
                  placeholder={t('personal.phoneNumber_placeholder')}
                />
              </div>
              <div className="form-group">
                <label>{t('personal.time_label')}</label>
                <select
                  name="time"
                  value={values.time}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="form-control"
                >
                  {timeOptions.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <h3>{t('detail.note')}</h3>
                <textarea
                  name="note"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.note}
                  placeholder={t('detail.note_placeholder')}
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-md-center mt-3">
            <div className="col-12 col-md-6">
              <button
                disabled={Object.keys(errors).length > 0 || (status && status.isLoading === true)}
                type="submit"
                className="btn btn-primary btn-block"
              >
                {this.buttonText(errors)}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const QuotationWithFormik = withFormik<QuotationProps, QuotationInitialValues>({
  mapPropsToValues: (props) => ({
    car_type: props.t(carTypes[0].name),
    full_name: '',
    email: '',
    phone_number: '',
    time: timeOptions[0],
    province: provinces[props.prefix][0],
    purposes: [],
    purpose_other: '',
    pickup_date: moment().add(3, 'days'),
    return_date: moment().add(3, 'days').add(1, 'months'),
    pickup_hour: '10:00',
    return_hour: '10:00',
    budget: budgets[0],
    car_type_other: '',
    note: ''
  }),
  validationSchema: quotationSchema(),
  validateOnChange: true,
  validateOnBlur: true,
  handleSubmit: (values, formikBag) => {
    const { t } = formikBag.props;
    const newValues = { ...values };
    if (values.purposes.includes(t('purposes.other'))) {
      newValues.purposes.push(values.purpose_other || '');
      newValues.purposes = newValues.purposes.filter((p) => p !== t('purposes.other'));
    }
    if (values.car_type === t('cars.other')) {
      newValues.car_type = values.car_type_other || '';
    }
    if (values.pickup_date && values.return_date) {
      newValues.pickup_at = `${values.pickup_date.format('DD MMMM YYYY')} ${values.pickup_hour}`;
      newValues.return_at = `${values.return_date.format('DD MMMM YYYY')} ${values.return_hour}`;
    }
    delete newValues.pickup_date;
    delete newValues.return_date;
    delete newValues.pickup_hour;
    delete newValues.return_hour;
    delete newValues.purpose_other;
    delete newValues.car_type_other;

    formikBag.setStatus({
      isLoading: true
    });
    axios({
      method: 'post',
      url: `${API_LONG_TERM_RENTAL}/long-term-rental/quotation`,
      data: {
        ...values
      }
    })
      .then(() => {
        formikBag.setStatus({
          isSuccess: true,
          isLoading: false
        });
        trackQuotationForm(LongTermFormResp.Success, values);
        navigate(`${WEB_LEGACY_LINK}/th/long-term-rental/successfully`);
      })
      .catch((e) => {
        formikBag.setStatus({
          isSuccess: false,
          isLoading: false
        });
        trackQuotationForm(LongTermFormResp.Fail, values);
        console.log(e);
      });
    formikBag.setSubmitting(false);
  },
  enableReinitialize: false
})(Quotation);

export default withTrans('long-term-rental')(QuotationWithFormik);
