import { PureComponent } from 'react';
import { Props, State } from './interface';
import { WEB_LEGACY_LINK } from '@config';
import Layout from '../../components/share-components/layout';
import SEO from '@shares/seo';
import Header from '@pages/long-term-rental/section/header';
import Context, { ContextType } from '@ui/components/context';
import HowToRent from '@pages/long-term-rental/section/how-to-rent';
import LongTermRentalForm from '@pages/long-term-rental/section/long-term-form';
import Services from '@shares/services';
import Regions from '@shares/regions';
import { PageSection } from '@utils';
import './style.scss';

class LongTermRentalPage extends PureComponent<Props, State> {
  static contextType = ContextType;
  render() {
    const { prefix } = this.props.pageContext;
    return (
      <Context>
        <Layout section={PageSection.LongTermRental} prefix={prefix}>
          <SEO
            prefix={prefix}
            title="Long Term Rental"
            description="Drivehub เช่ารถระะยาว เปิดบริการแล้ว รับราคาพิเศษ ง่ายๆ เพียงติดต่อเรา!"
            keywords="เช่ารถ, รถเช่า, ระยะยาว, 7วัน, 15 วัน"
            canonical={`${WEB_LEGACY_LINK}/${prefix}/long-term-rental/`}
            disableAppBanner
          />
          <div>
            <Header />
            <HowToRent />
            <LongTermRentalForm prefix={prefix} />
            <Services prefix={prefix} hideService sectionName={PageSection.LongTermRental} />
            <Regions prefix={prefix} sectionName={PageSection.LongTermRental} />
          </div>
        </Layout>
      </Context>
    );
  }
}

export default LongTermRentalPage;
